import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// import { ThemeProvider } from "@material-ui/core/styles";
// import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <ThemeProvider theme={theme}>
    <App />
  
  // <React.StrictMode>

  // </React.StrictMode>
);
